<template>
    <div>
        <el-form :inline="true">
            <!--            <el-form-item label="采集类型：" label-width="100">-->
            <!--                <el-select v-model="searchForm.isList" clearable placeholder="选择采集类型">-->
            <!--                    <el-option label="列表" value="1"></el-option>-->
            <!--                    <el-option label="详情" value="2"></el-option>-->
            <!--                </el-select>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="采集状况：" label-width="100">-->
            <!--                <el-select v-model="searchForm.isAcquisition" clearable placeholder="选择采集状态">-->
            <!--                    <el-option label="未采集" value="1"></el-option>-->
            <!--                    <el-option label="已采集" value="2"></el-option>-->
            <!--                </el-select>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item>-->
            <!--                <el-button @click="getAcquisitionTaskList" type="success"  >搜索</el-button>-->
            <!--            </el-form-item>-->

            <el-form-item>
                <el-button type="primary" @click="addOrUpdateAcquisitiontaskButton(null)" v-if="hasAuth('sys:wujiang:savewujiang')">添加采集任务</el-button>
            </el-form-item>
        </el-form>

        <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                border
                stripe
                @selection-change="handleSelectionChange">
            <el-table-column
                    label="采集网址"
                    align="center"
                    prop="acquisitionTaskUrl"
                    width="500">
            </el-table-column>
            <!--            <el-table-column-->
            <!--                    prop="isList"-->
            <!--                    align="center"-->
            <!--                    label="采集类型"-->
            <!--                    width="120">-->
            <!--                <template slot-scope="scope">-->
            <!--                    <el-tag size="small" v-if="scope.row.isList === 1" type="info">列表</el-tag>-->
            <!--                    <el-tag size="small" v-else-if="scope.row.isList ===2" type="success">详情</el-tag>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
            <el-table-column
                    prop="isAcquisition"
                    align="center"
                    label="采集状态"
                    width="120">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.isAcquisition === 0" type="success">未采集</el-tag>
                    <el-tag size="small" v-else-if="scope.row.isAcquisition === 1" type="info">已采集</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="createTime"
                    align="center"
                    width="200"
                    label="采集时间"
            >
            </el-table-column>
            <el-table-column
                    prop="icon"
                    align="center"
                    width="450"
                    fixed="right"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="addOrUpdateAcquisitiontaskButton(scope.row.acquisitionTaskId)" v-if="hasAuth('sys:wujiang:updatewujiang')">编辑</el-button>
                    <el-button  type="success" plain @click="addReptile(scope.row.acquisitionTaskId)" v-if="hasAuth('sys:wujiang:reptile')">采集</el-button>
                    <el-button  type="success" plain @click="collectionResults(scope.row.acquisitionTaskId)" v-if="hasAuth('sys:wujiang:seereptile')">查看采集结果</el-button>
                    <el-button icon="plus" @click="exportExcel(scope.row.acquisitionTaskId)" type="primary" v-if="hasAuth('sys:wujiang:export')">导出</el-button>
                    <template v-if="hasAuth('sys:wujiang:deletewujiang')">
                        <el-popconfirm title="确定删除该采集任务吗？" @confirm="delHandle(scope.row.acquisitionTaskId)">
                            <el-button type="danger" plain slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>

                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 50, 100]"
                :current-page="current"
                :page-size="size"
                :total="total">
        </el-pagination>
        <AddUpdateOfficialAccount ref="addOrUpdateAcquisitiontask" v-if="addOrUpdateAcquisitiontaskVisible" @refreshDataList="getAcquisitionTaskList"></AddUpdateOfficialAccount>
        <CollectionResults ref="collectionResults" v-if="collectionResultsVisible" @refreshDataList="getAcquisitionTaskList" ></CollectionResults>
    </div>
</template>

<script>
    import AddUpdateOfficialAccount from "./AddUpdateWujiang";
    import CollectionResults from "../acquisitiontask/CollectionResults";
    import ExportJsonExcel from "js-export-excel";
    export default {
        name: "OfficialAccount",
        components:{
            AddUpdateOfficialAccount,CollectionResults
        },
        data(){
            return{
                searchForm:{},
                delBtlStatu:true,
                tableData:[],
                multipleSelection:[],
                total: 0,
                size: 10,
                current: 1,
                addOrUpdateAcquisitiontaskVisible:false,
                collectionResultsVisible:false
            }
        },
        created() {
            this.getAcquisitionTaskList();
        },
        methods:{
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.delBtlStatu = val.length == 0
            },

            handleSizeChange(val) {
                this.size = val
                this.getAcquisitionTaskList()
            },
            handleCurrentChange(val) {
                this.current = val
                this.getAcquisitionTaskList()
            },
            //获取资讯列表
            getAcquisitionTaskList() {
                this.searchForm.pageNo=this.current
                this.searchForm.pageSize=this.size
                this.searchForm.acquisitionType=5
                this.$axios.post("/admin/acquisitiontask/getAcquisitionTaskList", this.searchForm).then(res => {
                    this.tableData = res.data.records
                    this.size = res.data.size
                    this.current = res.data.pages
                    this.total = res.data.total
                })
            },
            //删除资讯
            delHandle(id) {
                this.$axios.post("/admin/acquisitiontask/deleteAcquisitionTas", {acquisitionTaskId:id}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                        onClose:() => {
                            this.getAcquisitionTaskList()
                        }
                    });
                })
            },
            addOrUpdateAcquisitiontaskButton(id){
                this.addOrUpdateAcquisitiontaskVisible=true;
                this.$nextTick(()=>{
                    this.$refs.addOrUpdateAcquisitiontask.init(id);
                })
            },
            collectionResults(id){
                console.log('ssssssssssss')
                this.collectionResultsVisible=true
                this.$nextTick(()=>{
                    this.$refs.collectionResults.init(id)
                })
            },
            addReptile(id){
                const loading = this.$loading({
                    lock: true,
                    text: '正在采集',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios.post("/admin/reptile/addReptile", {acquisitionTaskId:id}).then(res => {
                    if (res.code==0){
                        this.$message({
                            showClose: true,
                            message: '恭喜你，操作成功',
                            type: 'success',
                            onClose:() => {
                                loading.close()
                                this.getAcquisitionTaskList()
                            }
                        });
                    }else {
                        loading.close()
                    }
                })
            },
            exportExcel(id) {
                const loading = this.$loading({
                    lock: true,
                    text: '正在导出中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                var searchForm={
                    "acquisitionTaskId":id,
                    "pageNo":1,
                    "pageSize":10000
                }
                var ids=[];
                this.$axios.post("/admin/reptile/exportExcel", searchForm).then(res => {
                    const dataList = res.data
                    let option = {};  //   option代表的就是excel文件
                    var  tableHeader=['标题','来源网址','图片地址','发布时间','作者', '发布者','浏览量', '文章来源','采集时间','视频地址','附件地址','附件地址','内容']
                    var tableWith=['40','30','30','10','6','6','6', '7','7','30','30','30','50']
                    let dataTable = [];   //   dataTable代表excel文件中的数据内容
                    if (dataList) {
                        for (let i in dataList) {
                            ids.push(dataList[i].reptileId)
                            let obj = {
                                标题: dataList[i].title,
                                来源网址: dataList[i].sourceUrl,
                                图片地址: dataList[i].imgUrl,
                                发布时间:dataList[i].releaseTime,
                                作者:dataList[i].author,
                                发布者:dataList[i].publisher,
                                浏览量:dataList[i].views,
                                文章来源:dataList[i].articleSource,
                                采集时间:dataList[i].createTime,
                                视频地址:dataList[i].videoUrl,
                                附件地址:dataList[i].enclosureUrl,
                                内容:dataList[i].publishingContent

                            };
                            dataTable.push(obj);   //   设置excel每列获取的数据源
                        }
                    }

                    option.fileName ="采集结果";  //excel文件名
                    //excel文件数据
                    option.datas = [
                        {
                            //   excel文件的数据源
                            sheetData: dataTable,
                            //   excel文件sheet的表名
                            sheetName: "采集结果",
                            //   excel文件表头名
                            sheetHeader: tableHeader,
                            //   excel文件列名
                            sheetFilter: tableHeader,
                            columnWidths: tableWith,
                        },
                    ];
                    //   创建ExportJsonExcel实例对象
                    let toExcel = new ExportJsonExcel(option,'sss');
                    //   调用保存方法
                    toExcel.saveExcel();
                    this.updateexport(ids)
                    loading.close()
                })

            },
            updateexport(ids){
                this.$axios.post("/admin/reptile/updateExport", {ids:ids}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                    });
                })
            }
        }
    }
</script>

<style scoped>
    .el-button{
        padding: 0 10px;
        height: 35px;
        line-height:35px;
        font-size:15px;
        margin-right: 10px;
    }
</style>

